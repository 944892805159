import * as Constants from './constants'
import * as R from 'ramda'
import * as Reselect from 'reselect'

// This file contains the redux boilerplate for "configuration" stuff
// That is the data needed to start a transaction

/**
 * Empty immutable object
 * Used for selector fallbacks
 * @constant {Object}
 */
const EMPTY_OBJECT = Object.freeze({})

/**
 * Empty immutable payment backend config
 * Used for selector fallbacks
 * @constant {Object}
 */
const EMPTY_BACKEND_CONFIG = Object.freeze({enabled: []})

/**
 * Action-type
 * @constant {string}
 */
const BACKENDS_UPDATED = 'payer/configuration/BACKENDS_UPDATED'

/**
 * Action-type
 * @constant {string}
 */
const INITIALIZE = 'payer/configuration/INITIALIZE'

/**
 * Action-type
 * @constant {string}
 */
const DEVICE_PIXEL_RATIO_UPDATED =
  'payer/configuration/DEVICE_PIXEL_RATIO_UPDATED'

/**
 * Updates configuration data
 * @param {Object} configuration-object
 * @param {string=} amountCents the amount for transaction - Not needed for 'AUTH'
 * @param {string=} brand brand-key to pass to HeroGaming BE for Trustly Pay-n-play flow
 * @param {('browser'|'mobile-browser')} clientType the users current clienttype
 * @param {string} countryCode the users country code according to ISO 3166-1
 * @param {string} currency the currency of the transaction
 * @param {string} depositCount the depositCount of the player
 * @param {string} language language to display application in
 * @param {string=} license Gambling license-code host application is running on
 * @param {string=} offerId Id of an Herogaming BackEend deposit-offer to claim on success
 * @param {string} origin The request origin
 * @param {string} token Herogaming BE user session-token
 * @param {string} userId Herogaming BE userId/username
 * @returns {Object} Redux action
 */
export function init({
  amountCents,
  brand,
  brandingEnabled,
  clientType,
  countryCode,
  currency,
  depositCount,
  seonSession,
  language,
  license,
  offerId,
  origin,
  parentOrigin,
  paymeroKey,
  token,
  userId,
}) {
  return {
    type: INITIALIZE,
    payload: {
      amountCents,
      brand,
      brandingEnabled,
      clientType,
      countryCode,
      currency,
      depositCount,
      seonSession,
      language,
      license,
      offerId,
      origin,
      parentOrigin,
      paymeroKey,
      token,
      userId,
    },
  }
}

/**
 * Updates payment-aggregators configuration data
 * @param {Object} config-data
 * @param {Object} brite configs for Brite
 * @param {Object} piq configs for PaymentIQ
 * @param {Object} projs configs for Pröjs
 * @param {Object} pwclick configs for PwClick
 * @param {Object} nodapay configs for NodaPay
 * @param {Object} transfer world configs for Transfer World
 * @param {Object} hello clever configs for HelloClever
 * @param {Object} vevo pay configs for VevoPay
 * @param {Object} zimpler configs for Zimpler
 * @param {Object} payust configs for Payust
 * @param {Object} luqapay configs for LuqaPay
 * @param {Object} megapay configs for MegaPay
 * @param {Object} approvely configs for Approvely
 * @returns {Object} Redux action
 */
export function updateBackends({
  brite,
  config,
  piq,
  piqKyc,
  projs,
  pwclick,
  nodapay,
  transferWorld,
  helloClever,
  vevoPay,
  zimpler,
  payust,
  luqapay,
  megapay,
  approvely,
}) {
  return {
    type: BACKENDS_UPDATED,
    payload: {
      brite,
      config,
      piq,
      piqKyc,
      projs,
      pwclick,
      nodapay,
      transferWorld,
      helloClever,
      vevoPay,
      zimpler,
      payust,
      luqapay,
      megapay,
      approvely,
    },
  }
}

/**
 * Updates device pixel ratio
 * @param {number} devicePixelRatio
 * @returns {Object} Redux action
 */
export function updateDevicePixelRatio(devicePixelRatio) {
  return {
    type: DEVICE_PIXEL_RATIO_UPDATED,
    payload: devicePixelRatio,
  }
}

/**
 * Helper function to get correct state
 * @param {Object} Redux state
 * @returns {Object} configuration state
 */
function getConfiguration(state) {
  return state.configuration
}

/**
 * Get payment aggrigators configurations
 * @param {Object} Redux state
 * @returns {Object}
 */
const getBackends = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.backends || EMPTY_OBJECT
)

/**
 * Get Api-url for config requests
 * @param {Object} Redux state
 * @returns {string}
 */
export const getApiUrl = Reselect.createSelector(
  getBackends,
  (backends) => backends.config.apiUrl
)

/**
 * Get payment aggrigators configurations
 * @param {Object} Redux state
 * @returns {string}
 */
export const getAmountCents = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.amountCents
)

/**
 * Get brand key
 * @param {Object} Redux state
 * @returns {string}
 */
export const getBrand = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.brand
)

/**
 * Get branding (theme override) ID
 * @param {Object} Redux state
 * @returns {string}
 */
export const getBrandingId = Reselect.createSelector(
  getConfiguration,
  (configuration) => {
    if (configuration.brandingEnabled) {
      return configuration.brand
    }
  }
)

/**
 * Get VevoPay configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getVevopayConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.vevoPay || EMPTY_BACKEND_CONFIG
)

/**
 * Get Brite configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getBriteConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.brite || EMPTY_BACKEND_CONFIG
)

/**
 * Get Zimpler configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getZimplerConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.zimpler || EMPTY_BACKEND_CONFIG
)

/**
 * Get client-type
 * @param {Object} Redux state
 * @returns {string}
 */
export const getClientType = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.clientType
)

/**
 * Get country-code
 * @param {Object} Redux state
 * @returns {string}
 */
export const getCountryCode = Reselect.createSelector(
  getConfiguration,
  (configuration) =>
    configuration.countryCode ? R.toUpper(configuration.countryCode) : null
)

/**
 * Get currency
 * @param {Object} Redux state
 * @returns {string}
 */
export const getCurrency = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.currency
)

/**
 * Get gamling deposit-count
 * @param {Object} Redux state
 * @returns {string}
 */
export const getDepositCount = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration?.depositCount
)

/**
 * Get device pixel ratio
 * @param {Object} Redux state
 * @returns {number}
 */
export const getDevicePixelRatio = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.devicePixelRatio
)

/**
 * Get environment
 * @param {Object} Redux state
 * @returns {string}
 */
export const getEnvironment = Reselect.createSelector(
  getBackends,
  (backends) => backends.config.environment
)

/**
 * Get device/visitor seonSession
 * @param {Object} Redux state
 * @returns {string}
 */
export const getSeonSession = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.seonSession
)

/**
 * Get url to request translations from
 * @param {Object} Redux state
 * @returns {string}
 */
export const getI18nProviderUrl = Reselect.createSelector(
  getBackends,
  (backends) => backends.config.i18nProviderUrl
)

/**
 * Get url to request image transformations on
 * @param {Object} Redux state
 * @returns {string}
 */
export const getImgProxyUrl = Reselect.createSelector(
  getBackends,
  (backends) => backends.config.imgProxyUrl
)

/**
 * Get language
 * @param {Object} Redux state
 * @returns {string}
 */
export const getLanguage = Reselect.createSelector(
  getConfiguration,
  (configuration) =>
    configuration.language ? R.toLower(configuration.language) : null
)

/**
 * Get gamling license-code
 * @param {Object} Redux state
 * @returns {string}
 */
export const getLicense = Reselect.createSelector(
  getConfiguration,
  (configuration) =>
    configuration.license ? R.toLower(configuration.license) : null
)

/**
 * Get locale (builds it based on language and countrycode)
 * @param {Object} Redux state
 * @returns {string}
 */
export const getLocale = Reselect.createSelector(
  [getConfiguration, getCountryCode, getLanguage],
  (configuration, countryCode, language) =>
    language && countryCode ? `${language}-${countryCode}` : null
)

export const getPaymentsLocale = Reselect.createSelector(
  getLanguage,
  (language) => R.pathOr('en', [language], Constants.languageIds)
)

/**
 * Get offerId
 * @param {Object} Redux state
 * @returns {string}
 */
export const getOfferId = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.offerId
)

/**
 * Get origin
 * @param {Object} Redux state
 * @returns {string}
 */
export const getOrigin = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.origin
)

/**
 * Get origin
 * @param {Object} Redux state
 * @returns {string}
 */
export const getParentOrigin = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.parentOrigin
)

/**
 * Get origin
 * @param {Object} Redux state
 * @returns {string}
 */

export const getPaymeroKey = Reselect.createSelector(
  getConfiguration,
  (configuration) => R.pathOr(null, ['paymeroKey'], configuration)
)

/**
 * Get PaymentIQ configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getPiqConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.piq || EMPTY_BACKEND_CONFIG
)

/**
 * Get PaymentIQ KYC configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getPiqKycConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.piqKyc || EMPTY_BACKEND_CONFIG
)

/**
 * Get PaymentIQ encrypter script url
 * @param {Object} Redux state
 * @returns {string}
 */
export const getPiqEncrypterSrc = Reselect.createSelector(
  [getPiqConfig],
  ({apiUrl, merchantId}) =>
    apiUrl && merchantId
      ? `${apiUrl}/api/viq/jscardencrypter/${merchantId}`
      : null
)

/**
 * Get Pröjs configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getProjsConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.projs || EMPTY_BACKEND_CONFIG
)

/**
 * Get Pwclick configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getPwclickConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.pwclick || EMPTY_BACKEND_CONFIG
)

/**
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getNodapayConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.nodapay || EMPTY_BACKEND_CONFIG
)

/**
 * Get Transfer World configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getTransferWorldConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.transferWorld || EMPTY_BACKEND_CONFIG
)

/**
 * Get Hello Clever configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getHelloCleverConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.helloClever || EMPTY_BACKEND_CONFIG
)

/**
 * Get Payust configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getPayustConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.payust || EMPTY_BACKEND_CONFIG
)

/**
 * Get LuqaPay configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getLuqapayConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.luqapay || EMPTY_BACKEND_CONFIG
)

/**
 * Get MegaPay configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getMegapayConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.megapay || EMPTY_BACKEND_CONFIG
)

/**
 * Get Approvely configuration
 * @param {Object} Redux state
 * @returns {Object}
 */
export const getApprovelyConfig = Reselect.createSelector(
  getBackends,
  (backends) => backends.approvely || EMPTY_BACKEND_CONFIG
)

/**
 * Get token (Session-token from Herogaming Backend)
 * @param {Object} Redux state
 * @returns {string}
 */
export const getToken = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.token
)

/**
 * Get user id (userId/username from Herogaming Backend)
 * @param {Object} Redux state
 * @returns {string}
 */
export const getUserId = Reselect.createSelector(
  getConfiguration,
  (configuration) => configuration.userId
)

/**
 * Manually selected data that is required for minimum transaction
 * Used for internal error-handling
 * @param {Object} Redux state
 * @returns {Object}
 */
const getBasicRequiredQueries = Reselect.createStructuredSelector({
  client_type: getClientType,
  country_code: getCountryCode,
  currency: getCurrency,
  language: getLanguage,
})

/**
 * Gives you the name of which of the queries are missing for minimum transaction
 * @param {Object} Redux state
 * @returns {string[]}
 */
export const getMissingRequiredQueries = Reselect.createSelector(
  getBasicRequiredQueries,
  (requiredQueries) =>
    R.filter(
      (key) => R.isNil(requiredQueries[key]) || R.isEmpty(requiredQueries[key]),
      R.keys(requiredQueries)
    )
)

/**
 * Gives you a boolean if any of the required queires is missing
 * @param {Object} Redux state
 * @returns {boolean}
 */
export const isMissingRequiredQueries = Reselect.createSelector(
  getMissingRequiredQueries,
  (missingRequiredQueries) => !R.isEmpty(missingRequiredQueries)
)

/**
 * Get default Projs BankId-type Based on client-type
 * Used to give a better user experience by pre-filling selection
 * @param {Object} Redux state
 * @returns {string}
 */
export const getDefaultProjsBankidType = Reselect.createSelector(
  getClientType,
  (clientType) => (clientType === 'mobile-browser' ? 'local' : 'remote_qr_code')
)

export const getLicenseLanguageCode = Reselect.createSelector(
  [getLanguage, getLicense],
  (language, license) =>
    language && license ? `${language}-x-${license}` : null
)

/**
 * Get the language codes to translate to. Ordered by priority
 * Example: ['foo-BAR', 'foo'] in this case if a translation is not found on `foo-BAR`
 * it'll try to find a translation using the next code in the list. In this case `foo`
 * @param {Object} Redux state
 * @returns {string[]} Array of language_codes used for translations
 */
export const getTranslationProviderLanguageCodes = Reselect.createSelector(
  [getLocale, getLicenseLanguageCode, getLanguage],
  (locale, license, language) =>
    R.filter((item) => !R.isNil(item), [locale, license, language])
)

/**
 * Configuration Reducer
 * @param {Object} state Redux state
 * @param {Object} action Redux action
 * @returns {Object} state management
 */
export function reducer(state = EMPTY_OBJECT, action) {
  switch (action.type) {
    case BACKENDS_UPDATED: {
      return {
        ...state,
        backends: action.payload,
      }
    }

    case INITIALIZE: {
      return {
        ...state,
        ...action.payload,
      }
    }

    case DEVICE_PIXEL_RATIO_UPDATED: {
      return {
        ...state,
        devicePixelRatio: action.payload,
      }
    }

    default: {
      return state
    }
  }
}
