import * as Actions from '../actions'
import * as Constants from '../constants'
import * as Processes from '@rushplay/processes'
import * as R from 'ramda'
import * as Request from '../request'
import * as date from 'date-fns'

import {bind} from 'redux-effects'
import {createPaymentMethodId} from '../create-provider-id'

function transformTransactionType(txType) {
  switch (txType) {
    case 'deposit': {
      return Constants.TransactionType.DEPOSIT
    }

    case 'withdraw': {
      return Constants.TransactionType.WITHDRAWAL
    }

    default: {
      throw new Error('Unknown transaction type')
    }
  }
}

function transformPaymentMethods(paymentMethods, txType) {
  return R.reduce(
    (methods, method) => {
      const transactionType = transformTransactionType(method.transaction_type)
      if (transactionType === txType) {
        const backendType = Constants.BackendType.MEGAPAY
        return R.append(
          R.map(
            (service) => ({
              accounts: [],
              backendType,
              canAddAccount: true,
              id: createPaymentMethodId({
                backendType,
                serviceType: service,
                providerType: method.provider,
                transactionType,
              }),
              limit: {
                max: `${method.limits.max_cents / 100}`,
                min: `${method.limits.min_cents / 100}`,
              },
              service: service,
              providerType: method.provider,
              transactionType,
            }),
            R.keys(method.services)
          ),
          methods
        )[0]
      }
      return methods
    },
    [],
    paymentMethods
  )
}

/**
 * Fetches user’s payment methods.
 *
 * @param {Object} params
 * @param {string} params.countryCode Country code used for currency conversion
 * @param {TransactionType} params.transactionType
 * @returns Redux Effects fetch action
 */
export function fetchPaymentMethods(params) {
  const processId = `megapay/fetch-payments-methods-by-user`
  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.MEGAPAY,
        requestData: {
          countryCode: params.countryCode,
        },
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: R.defaultTo('', params.sessionId),
        },
        requestMethod: 'GET',
        urlTemplate: '/megapay/payment_methods{?countryCode}',
      }),
      (res) => [
        Actions.updatePaymentMethods(
          transformPaymentMethods(res.value, params.transactionType)
        ),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Actions.updatePaymentMethods(
            new Error('errors.megapay.fetch-payment-methods-by-user-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

function transformPendingTransactions(transactions) {
  return R.sort(
    (a, b) =>
      date.getTime(new Date(b.created)) - date.getTime(new Date(a.created)),
    R.map(
      (transaction) =>
        R.mergeDeepRight(transaction, {
          amount: `${transaction.amount / 100} ${transaction.currency}`,
          backendType: Constants.BackendType.MEGAPAY,
          state: Constants.TransactionState.WAITING_APPROVAL,
          txType: transaction.txType,
        }),
      transactions
    )
  )
}

/**
 * Fetches user’s pending transactions.
 *
 * @param {Object} params
 * @param {SessionId} params.sessionId
 * @param {TransactionType} params.transactionType
 * @returns Redux Effects fetch action
 */
export function fetchPendingTransactions(params) {
  const processId = `megapay/fetch-pending-transactions`
  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.MEGAPAY,
        requestMethod: 'GET',
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: R.defaultTo('', params.sessionId),
        },
        urlTemplate: '/megapay/withdrawals',
      }),
      (res) => [
        Actions.updatePendingTransactions(
          transformPendingTransactions(res.value.result.transactions)
        ),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Actions.updatePendingTransactions(
            new Error('errors.megapay.fetch-pending-transactions-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

/**
 * Request cancel user's withdrawal.
 *
 * @param {Object} params
 * @param {SessionId} params.sessionId
 * @param {string} params.transactionId
 * @returns Redux Effects fetch action
 */
export function cancelPendingTransaction(params) {
  const processId = `cancel-pending-transaction-${params.transactionId}`
  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.MEGAPAY,
        requestData: {
          transactionId: params.transactionId,
        },
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: R.defaultTo('', params.sessionId),
        },
        requestMethod: 'DELETE',
        urlTemplate: '/megapay/withdrawals{/transactionId}',
      }),
      () => [
        Actions.removePendingTransaction(params.transactionId),
        Processes.stop(processId),
      ],
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Processes.stop(processId),
          Actions.removePendingTransaction(
            new Error('errors.megapay.cancel-pending-transaction-failure')
          ),
        ]
      }
    ),
  ]
}

/**
 * Start payment transaction
 *
 * @returns Redux Effects action
 */
export function performTransaction(params, transactionParams) {
  const processId = `perform-transaction-${params.paymentMethodId}`

  return [
    Processes.start(processId),
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.MEGAPAY,
        bodyTemplate: {
          amount_cents: true,
          transaction_type: true,
          payment_method: true,
          bank: true,
          walletno: true,
        },
        requestData: R.merge(transactionParams, {
          amount_cents:
            params.transactionType === 'withdrawal'
              ? `-${params.amountCents}`
              : params.amountCents,
          transaction_type: params.transactionType,
          payment_method: params.service,
        }),
        requestMethod: 'POST',
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: R.defaultTo('', params.sessionId),
        },
        urlTemplate:
          params.transactionType === 'deposit'
            ? '/megapay/deposits'
            : '/megapay/withdrawals',
      }),
      (res) => {
        const url = res.value?.redirect_url

        //todo: better handling of the withrawals
        if (!url && res.value.status === true) {
          return [
            Actions.updateTransaction({
              transactionState: Constants.TransactionState.SUCCESSFUL,
            }),
          ]
        }

        return [
          Actions.startTransaction({
            redirectOutput: {
              container: 'window',
              url: url,
              parameters: {},
            },
            transactionId: res.value?.transaction_id,
            paymentMethodId: params.paymentMethodId,
            transactionState: Constants.TransactionState.WAITING_INPUT,
          }),
          Processes.stop(processId),
        ]
      },
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }

        return [
          Actions.completeTransaction(
            new Error('errors.megapay.perform-transaction-failure')
          ),
          Processes.stop(processId),
        ]
      }
    ),
  ]
}

/**
 * Request transaction state update
 *
 * @param {Object} params
 * @param {MerchantId} params.merchantId
 * @param {SessionId} params.sessionId
 * @param {string} params.transactionId
 * @param {UserId} params.userId
 * @returns Redux Effects fetch action
 */
export function fetchTransactionState(params) {
  return [
    bind(
      Request.createRequest({
        backendType: Constants.BackendType.MEGAPAY,
        requestData: {
          id: params.transactionId,
          type:
            params.transactionType === 'deposit' ? 'deposits' : 'withdrawals',
        },
        requestMethod: 'GET',
        requestHeaders: {
          Accept: 'application/vnd.casinosaga.v1, application/json',
          Authorization: R.defaultTo('', params.sessionId),
        },
        urlTemplate: '/megapay/{type}/{id}',
      }),
      (res) => {
        if (res.value.status == 'accepted') {
          return [
            Actions.updateTransaction({
              transactionState: Constants.TransactionState.SUCCESSFUL,
            }),
          ]
        }
        if (res.value.status == 'cancelled') {
          return [
            Actions.updateTransaction({
              transactionState: Constants.TransactionState.CANCELLED,
            }),
          ]
        }
      },
      (error) => {
        if (process.env.NODE_ENV !== 'production') {
          // Pass real error to console for easier debugging
          // eslint-disable-next-line no-console
          console.error(error)
        }
        return [
          Actions.updateTransaction({
            transactionState: Constants.TransactionState.FAILED,
          }),
        ]
      }
    ),
  ]
}
